import React from "react"
import theme from "theme"
import { Theme, Text, Box, Link, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contacts"} />
      <Helmet>
        <title>Оренда яхт AzureWave</title>
        <meta
          name={"description"}
          content={"Досліджуйте моря зі стилем і простотою"}
        />
        <meta property={"og:title"} content={"Оренда яхт AzureWave"} />
        <meta
          property={"og:description"}
          content={"Досліджуйте моря зі стилем і простотою"}
        />
        <meta
          property={"og:image"}
          content={"https://up.lixorev.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://up.lixorev.com/img/image93.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://up.lixorev.com/img/image93.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://up.lixorev.com/img/image93.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 80px 0"
        color="--darkD2"
        quarkly-title="Contacts-8"
        min-height="70vh"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          align-items="flex-start"
          lg-flex-direction="column"
        />
        <Box
          min-width="100px"
          min-height="100px"
          width="60%"
          lg-width="100%"
          display="flex"
          flex-direction="column"
          justify-content="center"
          lg-margin="0px 0 30px 0px"
          align-items="flex-start"
          sm-margin="0px 0px 0 0px"
          lg-padding="0px 180px 0px 0px"
          sm-padding="0px 0 0px 0px"
          md-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 20px 0px"
            font="normal 600 38px/1.2 --fontFamily-sansHelvetica"
            color="--dark"
          >
            Зв'яжіться з нами
          </Text>
          <Text
            margin="0px 0px 50px 0px"
            font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
            color="--darkD2"
            lg-margin="0px 0px 20px 0px"
            md-width="100%"
          >
            Готові до відплиття? Зв'яжіться з нами, щоб забронювати ідеальну
            подорож на яхті:
          </Text>
        </Box>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          grid-gap="16px 24px"
          margin="0px 0 0 10%"
          lg-grid-gap="36px 34px"
          flex-direction="column"
          width="50%"
          lg-margin="0px 0 0 0"
          lg-width="100%"
        >
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            flex-direction="row"
            border-color="--color-lightD2"
            border-radius="4px"
            align-items="center"
            margin="0px 0px 30px 0px"
            lg-margin="0px 0px 10px 0px"
            sm-margin="0px 0px 0 0px"
          >
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              flex-direction="column"
              align-items="flex-start"
              md-justify-content="center"
            >
              <Text
                color="--dark"
                font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
                display="block"
                margin="0px 0px 5px 0px"
                sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
              >
                Роберта Лісовскохо 1
              </Text>
              <Text
                margin="0px 0px 0 0px"
                font="normal 300 16px/1.2 --fontFamily-sans"
                text-align="center"
                color="--darkD2"
                md-margin="0px 0px 0px 0px"
              >
                Київ, 02000
              </Text>
            </Box>
          </Box>
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            flex-direction="row"
            border-color="--color-lightD2"
            border-radius="4px"
            align-items="center"
            margin="0px 0px 30px 0px"
            lg-margin="0px 0px 10px 0px"
            sm-margin="0px 0px 0 0px"
          >
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              flex-direction="column"
              align-items="flex-start"
              md-justify-content="center"
            >
              <Link
                href="tel:044 593 0310"
                color="--dark"
                text-decoration-line="initial"
                font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
                display="block"
                margin="0px 0px 5px 0px"
                sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
              >
                044 593 0310
              </Link>
            </Box>
          </Box>
          <Box
            min-width="10px"
            min-height="10px"
            display="flex"
            flex-direction="row"
            border-color="--color-lightD2"
            border-radius="4px"
            align-items="center"
          >
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              flex-direction="column"
              align-items="flex-start"
              md-justify-content="center"
            >
              <Link
                href="mailto:about@up.lixorev.com"
                color="--dark"
                text-decoration-line="initial"
                font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
                display="block"
                margin="0px 0px 5px 0px"
                sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
              >
                about@up.lixorev.com
              </Link>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
